import cdiLocationCache from '@/cache/cdiLocation.cache';
import creditCache from '@/cache/credit.cache';
import tracketCache from '@/cache/tracker.cache';

export const creditStore = {
	state: {
		name: 'credit',
		title: 'Credit',
		titlePlural: 'Credits',
		nav: {
			base: 'credit',
			newView: true,
			views: [
				{ view: 'details', label: 'Details', },
			]
		},
		search: {
			sortFields: true,
			endPoint: 'credits',
			formFields: {
				company: {
					val: '',
					qsField: '[numbers][credit.company_id]',
					label: 'Company',
					component: 'SearchTypeaheadInput',
					cdiType: 'company'
				},
				creditDateFrom: {
					val: '',
					qsField: '[dates][credit_date_from]',
					label: 'Credit Date From',
					component: 'SearchDateInput'
				},
				creditDateTo: {
					val: '',
					qsField: '[dates][credit_date_to]',
					label: 'Credit Date To',
					component: 'SearchDateInput'
				},
				openOnly: {
					val: '1',
					qsField: '[isnot][payment_id]',
					label: 'Open Only',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '1',
				},
				closedOnly: {
					val: '',
					qsField: '[isset][payment_id]',
					label: 'Closed Only',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				enabledOnly: {
					val: '1',
					qsField: '[isnot][credit_disable]',
					label: 'Enabled Only',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '1',
				},
				orderBy: {
					val: 'credit_date',
					qsField: '[orderby]',
					options: {
						'credit_date': 'Credit Date',
						'credit_po': 'Credit PO#'
					}
				},
				direction: {
					val: 'DESC',
					qsField: '[direction]'
				},
                searchIndex: {val: 0, qsField: '[search_index]'},
			},
			resultFields: {
				credit_id: {header: 'Credit ID', link: 'credit'},
				credit_date: {header: 'Date', link: ''},
				company_id: {header: 'Company', link: 'company', textField: 'company_name'},
				tracker_id: {header: 'Order', link: 'tracker'},
				payment_id: {header: 'Payment', link: 'payment'},
				credit_po: {header: 'PO', link: ''},
				credit_amount: {header: 'Amount', link: ''}
			},
		},
		object: {
			fields: {
				credit_id: {
					name: 'credit_id',
					label: 'Credit ID',
					component: 'DetailsStatic'
				},
				company_id: {
					name: 'company_id',
					label: 'Company',
					component: 'DetailsLink',
					displayField: 'company_name',
                    link: 'company',
				},
				cdiLocation: {
					name: 'cdi_location_id',
					label: 'CDI Location',
					component: 'DetailsSelect',
					options: cdiLocationCache.CDI_LOCATIONS
				},
				creditType: {
					name: 'credit_type_id',
					label: 'Type',
					component: 'DetailsSelect',
					options: [[1, 'None']].concat(creditCache.CREDIT_TYPES)
				},
				remakeType: {
					name: 'remake_type_id',
					label: 'MER Type',
					component: 'DetailsSelect',
					options: [[0, 'None']].concat(tracketCache.REMAKE_TYPES)
				},
				regionalSalesRep: {
					name: 'sales_user_id',
					label: 'Regional Sales Rep',
					component: 'DetailsTypeahead',
					cdiType: 'employee',
					displayField: 'sales_user'
				},
				salesSupportRep: {
					name: 'inside_sales_user_id',
					label: 'Sales Support Rep',
					component: 'DetailsTypeahead',
					cdiType: 'employee',
					displayField: 'inside_sales_user'
				},
				billTo: {
					name: 'company_bill_to_id',
					label: 'Bill To',
				},
				po: {
					name: 'credit_po',
					label: 'PO',
					component: 'DetailsText',
				},
				creditDate: {
					name: 'credit_date',
					label: 'Date',
					component: 'DetailsDate'
				},
				orderId: {
					name: 'tracker_id',
					label: 'Order ID',
					component: 'DetailsLink',
					displayField: 'tracker_id',
					link: 'tracker'
				},
				disable: {
					name: 'credit_disable',
					label: 'Disable',
					component: 'DetailsCheckbox'
				},
				notes: {
					name: 'credit_notes',
					label: 'Notes',
					component: 'DetailsTextarea'
				}
			}
		}
	}
}