<template>
    <div>
        <h1>Credits</h1>
        <div class="row">
            <div class="col-sm-6 col-xs-12 form-group">
                <label>Company</label>
                <typeahead :cdi_type="'company'" v-bind:ID.sync="companyId" />
            </div>
        </div>
        <input class="btn btn-default" type="button" @click="addCredit" value="Add Credit" >
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import Typeahead from "@/components/utils/typeaheads/Typeahead";

export default {
    name: 'New',
    components: {Typeahead},
    data() {
        return {
            state: store.state,
            companyId: 0
        }
    },
    methods: {
        addCredit: function() {
            this.state.object.id = 0;
            store.api('new_credit', {'params' : this.companyId}).then( result => {
                this.$router.push({path: `/credit/${result.credit_id}`});
            });
        }
    }
}

</script>