<template>
    <div>
        <h3>Items</h3>
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Description</th>
                    <th class="number">Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(creditItem, index) in creditItems" v-bind:key="index">
                    <td>
                        <input @keyup.enter="saveCreditItem(creditItem)" type="text" v-model="creditItem.credit_description" class="form-control">
                    </td>
                    <td class="number" style="width: 25%;">
                        <div class="input-group">
                            <span class="input-group-addon mobile_hide">$</span>
                            <input @keyup.enter="saveCreditItem(creditItem)" type="text" v-model="creditItem.credit_item_amount" class="form-control number">
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input @keyup.enter="newCreditItem" type="text" v-model="newDescription" class="form-control">
                    </td>
                    <td class="number" style="width: 25%;">
                        <div class="input-group">
                            <span class="input-group-addon mobile_hide">$</span>
                            <input @keyup.enter="newCreditItem" type="text" v-model="newAmount" class="form-control number">
                        </div>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <td class="right"><label>Total</label></td>
                <td class="number">${{ total }}</td>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import helpers from '@/store/helpers';

export default {
    name: 'Items',
    props: ['creditItems'],
    data() {
        return {
            newDescription: '',
            newAmount: '',
            editForm: {
                id:             {val: '', qsField: '[credit_item_id]'},
                description:    {val: '', qsField: '[credit_description]'},
                amount:         {val: '', qsField: '[credit_item_amount]'}
            }
        }
    },
    methods: {
        newCreditItem: function() {
            this.editForm.id.val = 0;
            this.editForm.description.val = this.newDescription;
            this.editForm.amount.val = this.newAmount;
            this.saveApiCall();
            this.clear();
        },
        saveApiCall: function() {
            store.apiWithObjectLoad('save_credit_item', helpers.getQSPairs(this.editForm))
        },
        saveCreditItem: function(creditItem) {
            this.editForm.id.val = creditItem.credit_item_id;
            this.editForm.description.val = creditItem.credit_description;
            this.editForm.amount.val = creditItem.credit_item_amount;
            this.saveApiCall();
            this.clear();
        },
        clear: function() {
            this.newAmount = '';
            this.newDescription = '';
            this.editForm.id.val = '';
            this.editForm.description.val = '';
            this.editForm.amount.val = '';
        }
    },
    computed: {
        total: function () {
            if (typeof this.creditItems === 'undefined') {
                return 0;
            }

            let total = 0;
            this.creditItems.forEach(credit => total += parseFloat (credit.credit_item_amount));

            if (this.newAmount > 0) {
                total += parseFloat(this.newAmount);
            }

            return total.toFixed(2);
        }
    }
}



</script>