export default {
	CREDIT_TYPES: [
		[190, 'Commission'],
		[191, 'Marketing'],
		[192, 'Rebate'],
		[193, 'Collections Fee'],
		[194, 'Bad Debt'],
		[195, 'Sales Tax Credit'],
		[196, 'Overpayment'],
		[280, 'Good Will'],
		[384, 'Unearned Payment Discount']
	]
}