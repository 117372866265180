export default {

    REMAKE_TYPES: [
        [10, 'Commission (legacy)'],
        [11, 'Order Entry'],
        [12, 'Design'],
        [13, 'Production'],
        [14, 'Freight'],
        [15, 'Customer'],
        [16, 'Reorder'],
        [31, 'Sales'],
        [226, 'Applied Design'],
        [368, 'Measure'],
        [374, 'RMA'],
        [375, 'Misc']
    ],

    TRACKER_MER_REMAKE_TYPES: [
        [394, 'Accounting'],
        [15, 'Customer'],
        [12, 'Design'],
        [14, 'Freight'],
        [368, 'Measure'],
        [375, 'Misc'],
        [13, 'Production'],
        [16, 'Reorder'],
        [374, 'RMA'],
        [31, 'Sales'],
        [395, 'Sales Measure']
    ],

    TRACKER_REWORK_OPTS: [
        'Batching Error',
        'Design Error',
        'Equipment Error',
        'Folding Error',
        'Insulation/Crate Error',
        'Welding Error'
],

HISTORICAL_TRACKER_REWORK_OPTS: [
        "Batching Error",
        "Brace Dimension Error",
        "Brace Location",
        "Brace Missing",
        "Bottom Bent",
        "Bottom Location",
        "Bottom Missing",
        "Bottom Weld",
        "Brace top crooked",
        "Clinching Error",
        "Cover Location",
        "Cover Missing",
        "Design Error",
        "Drawing Incorrect",
        "Equipment Error",
        "Equipment Failure",
        "Folding Error",
        "Filler Location",
        "Filler Missing",
        "Gauge Incorrect",
        "Insulation/Crate Error",
        "Insulation missing",
        "Insulation Substandard",
        "Insulation Wrong type",
        "Label Wrong",
        "Misc Location",
        "Misc Missing",
        "Not square",
        "OD Beyond limits",
        "Process Not Followed",
        "Scrap Curb",
        "Tie Down Missing",
        "Verticals missing",
        "Wall Angle",
        "Wall Break Wrong",
        "Wall Location",
        "Wall Missing",
        "Welding Error",
        "Weld Assembly error",
        "Weld Rework",
        "24 Gauge Missing",
        "3D Design Error"
    ],
    
    TRACKER_ENG_REWORK_OPTS: [
        "Alt Braces Needed",
        "Brace Dimension Error",
        "Conflicting Information",
        "Cover Missing",
        "Design Modification",
        "Dropped braces missing",
        "Eng Design Standard Not Followed",
        "Eng File Does Not Match Line Item",
        "Eng File Does Not Match Notebook",
        "Eng File Does Not Match VD",
        "Eng File Missing Details",
        "Existing curb dimension error",
        "Gauge Incorrect",
        "Improper Airflow",
        "Information Change",
        "Information Unclear",
        "Machine Issue",
        "Missing DXF(s)",
        "Need Lift Points",
        "Needs AI cross",
        "New Curb dimension error",
        "Pitch Backwards/Incorrect",
        "R/S label incorrect",
        "Special Materials Not Checked",
        "Vertical AI Missing",
        "Wall Location",
        "Wall Missing",
        "Wrong configuration in views",
        "24 Gauge Missing",
        "3D Design Error"
    ],

    CAUSE_TYPES_HISTORICAL: [
        [178, 'Measurement Error'],
        [179, 'Freight Damage'],
        [180, 'Missed Customer Information'],
        [181, 'Design Error/Drawing Incorrect'],
        [182, 'Curb Pitch Incorrect'],
        [183, 'Curb Missing Parts'],
        [184, 'Curb Labeled Wrong'],
        [185, 'Wrong Curb Shipped'],
        [186, 'Misc Cause (see notes)'],
        [339, 'People'],
        [340, 'Materials'],
        [341, 'Process'],
        [342, 'Machines'],
        [343, 'Supply Chain'],
    ],

    TRACKER_MER_CAUSE_TYPES: [
        [396, 'Air Flow Issue'],
        [397, 'Air Leak'],
        [398, 'Billing/Pricing Issue'],
        [399, 'Curb - Does Not Fit'],
        [400, 'Curb - Incorrect Configuration'],
        [424, 'Curb Not Built to Drawing'],
        [401, 'Curb - Pitch Is Incorrect'],
        [402, 'Curb - Wrong Curb Shipped'],
        [403, 'Curb/Unit Not Level'],
        [404, 'Customer Ordered The Wrong Curb'],
        [405, 'Data Was Entered Incorrectly'],
        [406, 'Freight Damage'],
        [407, 'Freight Lost'],
        [420, 'Freight Not Received on Time'],
        [419, 'Freight Not Shipped on Time'],
        [408, 'Gasket Not Included/Not Enough'],
        [409, 'ISO Spring Issue'],
        [410, 'Measure Issue'],
        [411, 'Missing Parts'],
        [417, 'Not Designed to Customer/Measure Drawing'],
        [418, 'Not Built as Designed'],
        [412, 'Quality Poor'],
        [413, 'Returned Merchandise Authorized - RMA'],
        [414, 'Shipped Late'],
        [415, 'Water Leak'],
        [416, 'Other'],
    ],

    TRACKER_MER_RESOLUTIONS: [
        [344, 'Credit Issued'],
        [345, 'No Credit Issued'],
        [346, 'Field Fix (CDI)'],
        [347, 'Field Fix (Other)'],
        [348, 'Remake'],
        [392, 'Ship Back and Forth'],
        [393, 'Billing Correction']
    ],

    TRACKER_MER_SECONDARY_DESIGN: [
        [349, 'Existing Curb Error'],
        [350, 'Template'],
        [351, 'New Curb Drawing Error'],
        [352, 'Curb Does Not Match VD'],
        [353, 'Training'],
        [354, 'Spring Calc Issue'],
        [355, 'Designed Incorrectly'],
    ],

    TRACKER_MER_SECONDARY_OPERATIONS: [
        [356, 'Materials'],
        [357, 'Turret'],
        [358, 'Welding'],
        [359, 'Insulating'],
        [360, 'Crating'],
        [361, 'Shipping'],
    ],

    TRACKER_MER_SECONDARY_SALES: [
        [362, 'People'],
        [363, 'Typo'],
        [364, 'Quoted Wrong Item'],
        [365, 'Pulled Wrong Dimensions'],
        [366, 'Grabbed Wrong Drawing'],
        [367, 'Incorrect Specs'],
    ],

    TRACKER_REMAKE_OPTIONS: [
        [15, 'Customer'],
        [12, 'Design'],
        [14, 'Freight'],
        [11, 'Order Entry'],
        [13, 'Production'],
        [16, 'Reorder'],
        [31, 'Sales'],
        [375,'Miscellaneous']
    ],

    TRACKER_REMAKE_OPTIONS_HISTORICAL: [
        [10, 'Commission'],
        [226, 'Applied Design'],
        [368, 'Measure'],
        [374, 'RMS'],
        [394, 'Accounting'],
        [15, 'Customer'],
        [12, 'Design'],
        [14, 'Freight'],
        [11, 'Order Entry'],
        [13, 'Production'],
        [16, 'Reorder'],
        [31, 'Sales'],
        [375,'Miscellaneous']
    ]
}

