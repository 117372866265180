<template>
    <div>
        <div v-if="id">
            <h1>Credit {{ state.object.data.credit_id }}</h1>
            <div v-show="state.object.data.payment_id === 0" class="alert alert-info">
                <span class="cdi_red">This an Open Credit</span>
            </div>
            <div v-show="state.object.data.payment_id" class="alert alert-info">
                This Credit was used by payment <router-link :to="`/payment/${state.object.data.payment_id}`">#{{ state.object.data.payment_id }}</router-link>
            </div>
            <div class="spacing_btm">
                <ProtectedLink class="credit_pdf" :linkType="'button'" :classes="'cdi_button btn btn-default'" :text="'PDF'" :id="id" :queryString="queryString" :type="'credit'" />
                <input class='cdi_button lock btn btn-default' @click='emailCredit' type='button' value='Email'>
            </div>
            <Details :data="state.object.data" v-show="isCurrentView('details')" />


            <label class="object_detail_select">Bill To</label>
            <select
                class="cdi_select form-control"
                @change="saveBillTo"
                v-model="state.object.data.company_bill_to_id"
            >
                <option  v-for="(item, index) in billTo" v-bind:key="index" :value="item.company_bill_to_id" >{{ item.cdi_address_label }}</option>
            </select>

            <Items :creditItems="state.object.data.credit_items" v-show="isCurrentView('details')" />
            <History :history="state.object.data.history" v-show="isCurrentView('details')" />
        </div>

        <New v-else-if="isCurrentView('new')" />

        <Search v-else />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import { creditStore } from './Credit.store.js';
import Search from '@/components/businessObjects/utils/Search.vue';
import New from './New';
import Details from '@/components/businessObjects/utils/Details';
import History from '@/components/businessObjects/utils/History';
import Items from './Items';
import helpers from "@/store/helpers";
import ProtectedLink from "../../utils/ProtectedLink";

export default {
    name: 'Container',
    data() {
        return {
            state: store.state,
            creditState: creditStore.state,

        }
    },
    props: ['id'],
    components: {
        Items,
        Search,
        Details,
        History,
        New,
        ProtectedLink,

    },
    created() {
        store.initialize(this.creditState);
        this.load();

    },
    methods: {
       save: function(){
           store.apiWithObjectLoad('save', helpers.getQSPairs(this.formFields));
        },
        saveBillTo: function (){
            store.save('company_bill_to_id',this.state.object.data.company_bill_to_id)
        },
        load: function() {
            if (this.id){
                store.load(this.id)
                    .then(() => this.$appStore.setTitle([`${this.state.title} ${this.state.object.data.credit_id}`]))
            }
        },
        isCurrentView: function(view) {
            return view === this.state.currentView;
        },
        creditPdf: function() {
            window.open(`${this.$cdiVars.API_URL}/credit/to_pdf/${this.state.object.data.credit_id}?pdf=1&v=${Math.floor(Math.random() * 101)}`, '_blank');
        },
        emailCredit: function() {
            store.api(`email_credit`,{'pdf':1});
        },
    },
    computed: {
        billTo: function (){
            let billTo = this.state.object.data.bill_tos;
            return billTo
        },
        currentView: function() {
            return this.state.currentView;
        },
        queryString: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        }
    },
    watch: {
        id: function (){
            this.load();
        },
        currentView: function() {
          if(this.currentView == 'search'){
                window.location.reload()
            }
        }
    }
}
</script>

<style scoped>
.credit_pdf{
    margin: auto;
    display: block;
    width: fit-content;
    float: left;
}
</style>